<section id="contacto">
  <div class="container">
    <h2 class="titulo">Contacto</h2>
    <iframe loading="lazy" title="Mapa de la locación del restaurant Status" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3283.731332619629!2d-58.391098784770136!3d-34.61095468045765!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bccadc9e65875b%3A0x5d35696c8a1a2686!2sVirrey%20Cevallos%20178%2C%20C1077AAD%20CABA!5e0!3m2!1ses!2sar!4v1583819535528!5m2!1ses!2sar" style="border:0;" allowfullscreen=""></iframe>
    <div class="contactos">
      <div class="contacto-container">
        <img class="icon" src="location-dot.svg" alt="Icono de locación"/>
        <p class="descripcion-contacto">Virrey Cevallos 178</p>
      </div>
      <div class="contacto-container">
        <img class="icon" src="phone.svg" alt="Icono de locación"/>
        <p class="descripcion-contacto">11-6401-0786 / 11-6162-4485 / 11-3573-7062</p>
      </div>
    </div>
  </div>
</section>

<style>
  .container {
    margin: 0 auto;
    max-width: 1280px;
    padding: 64px;
  }

  .titulo {
    font-size: 3.5rem;
    margin-bottom: 24px;
    margin-top: 0;
  }

  iframe {
    height: 500px;
    width: 100%;
  }

  .contactos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .contacto-container {
    align-items: center;
    display: flex;
    margin-top: 48px;
  }

  .icon {
    margin-right: 12px;
    width: 1.5rem;
  }

  .descripcion-contacto {
    font-size: 1.5rem;
    margin: 0;
  }

  @media (max-width: 768px) {
    .container {
      padding: 32px;
    }

    .titulo {
      font-size: 2.5rem;
    }

    .contacto-container {
      margin-top: 24px;
    }

    .descripcion-contacto {
      font-size: 1rem;
    }
  }
</style>
