<section>
  <div class="hero-container">
    <h1 class="titulo">Pioneros en Gastronomía</h1>
    <h2 class="subtitulo">Fundado en enero de 1990, Status es el primer restaurante peruano en Argentina</h2>
    <div class="hero-fotos">
      <img class="foto-vinos" src="wine-bottles.webp" alt="Botellas de vino de cerca con huacos peruanos desenfocados detrás."/>
      <img class="foto-fachada" src="status-photo.jpeg" alt="Fachada del restaurante Status vista desde afuera, se ven comensales a través del vidrio."/>
    </div>
    <div class="encontranos">
      <p>Llamanos o hacé tu pedido por Whatsapp</p>
      <a class="whatsapp-number" href="https://wa.me/54111566852166" target="_blank">
        <img class="whatsapp-logo" src="whatsapp-logo.webp" alt="Logo de Whatsapp"/>
        11-6685-2166
      </a>
    </div>
  </div>
</section>

<style>
  .hero-container {
    display: grid;
    grid-gap: 0 32px;
    grid-template-areas:
        "titulo fotos"
        "subtitulo fotos"
        "encontranos fotos";
    grid-template-columns: 36% 1fr;
    margin: 0 auto;
    max-width: 1280px;
    min-height: 600px;
    padding: 32px 64px 64px;
    width: 100%;
  }

  .titulo {
    font-size: 4rem;
    grid-area: titulo;
    line-height: 1.1;
    margin-bottom: 0;
    margin-top: 60px;
  }

  .subtitulo {
    font-size: 1.75rem;
    font-weight: normal;
    grid-area: subtitulo;
  }

  .hero-fotos {
    background-image: url("/blob-shape.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    grid-area: fotos;
    position: relative;
  }

  .foto-vinos {
    border-radius: 128px 0;
    height: 300px;
    position: absolute;
    right: 0;
    top: 0;
  }

  .foto-fachada {
    border-radius: 24px;
    height: 208px;
    position: absolute;
    right: 320px;
    top: 265px;
  }

  .encontranos {
    align-self: end;
    grid-area: encontranos;
  }

  .whatsapp-logo {
    margin-right: 8px;
    width: 2.5rem;
  }

  .whatsapp-number {
    align-items: center;
    color: var(--color-primario);
    display: flex;
    font-size: 1.75rem;
    font-weight: 500;
  }

  @media (max-width: 1200px) {
    .hero-container {
      grid-template-areas: "fotos" "titulo" "subtitulo" "encontranos";
      grid-template-columns: auto;
    }

    .titulo {
      font-size: 3em;
    }

    .subtitulo {
      font-size: 1.25rem;
    }

    .encontranos p {
      margin: 16px 0 12px;
    }

    .whatsapp-number {
      font-size: 1.5rem;
    }

    .hero-fotos {
      height: 500px;
      position: unset;
    }

    .foto-vinos {
      right: 12%;
      top: 120px;
    }

    .foto-fachada {
      left: 15%;
      top: 380px;
    }
  }

  @media (max-width: 768px) {
    .hero-container {
      padding: 16px 32px 32px;
    }

    .hero-fotos {
      background-position: 100% 120%;
      height: 320px;
    }

    .foto-vinos {
      border-radius: 96px 0;
      height: 200px;
      right: 0;
      top: 100px;
    }

    .foto-fachada {
      border-radius: 16px;
      height: 160px;
      left: 0;
      top: 275px;
    }
  }
</style>
