<nav class="navbar">
  <div class="logo">
    <h3 class="titulo">Status</h3>
    <h4 class="subtitulo">Cocina típica del Perú</h4>
  </div>
  <a class="item" href="/Menu-Status.pdf" target="_blank">Menú</a>
  <a class="item" href="/#contacto">Contacto</a>
</nav>

<style>
  .navbar > .item {
    color: var(--color-primario);
    font-size: 1.2rem;
    margin: 0 1rem;
    text-decoration: none;
  }

  .navbar {
    align-items: center;
    display: flex;
    font-weight: 600;
    justify-content: flex-end;
    padding: 16px 24px;
  }

  .logo {
    margin-right: auto;
    position: relative;
  }

  .logo::after {
    background-color: var(--color-secundario);
    bottom: 0;
    content: '';
    height: 5px;
    left: 0;
    position: absolute;
    transform: translate(-32px, 24px);
    width: 13rem;
  }

  .titulo {
    font-size: 1.75rem;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 0;
  }

  .subtitulo {
    font-size: .8rem;
    font-weight: normal;
    margin: 0;
  }

  @media (max-width: 768px) {
    .navbar > .item {
      font-size: 1rem;
    }

    .navbar {
      padding: 16px;
    }

    .logo::after {
      height: 4px;
      transform: translate(-32px, 22px);
      width: 10rem;
    }

    .titulo {
      font-size: 1.5rem;
    }

    .subtitulo {
      font-size: .6rem;
    }
  }
</style>
