<footer>
  <div class="container">
    <a href="https://www.instagram.com/restaurant.status/" target="_blank"><img class="icon instagram" src="instagram-logo.svg" alt="Logo de Instagram"/></a>
    <a href="https://www.pedidosya.com.ar/restaurantes/buenos-aires/status-menu" target="_blank"><img class="icon pedidos-ya" src="pedidos-ya-logo.webp" alt="Logo de PedidosYa"/></a>
  </div>
</footer>

<style>
  footer {
    background-color: var(--negro);
    color: var(--gris);
    padding: 24px 20px 20px;
  }

  .container {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 1280px;
    width: 100%;
  }

  .icon {
    height: 36px;
    margin: 0 32px;
  }

  .icon.instagram {
    filter: invert(0.6);
  }

  .icon.pedidos-ya {
    filter: grayscale(1) brightness(2);
  }
</style>
