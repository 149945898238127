<script>
	import Navbar from "./Navbar.svelte";
	import Hero from "./Hero.svelte";
	import ConoceNuestroMenu from "./ConoceNuestroMenu.svelte";
	import Contacto from "./Contacto.svelte";
	import Footer from "./Footer.svelte";
</script>

<svelte:head>
	<link href="https://fonts.googleapis.com/css?family=Asap:400,500,600,700&display=swap" rel="stylesheet">
</svelte:head>

<main>
	<Navbar/>
	<Hero/>
	<ConoceNuestroMenu/>
	<Contacto/>
	<Footer/>
</main>

<style>


</style>
