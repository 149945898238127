<section>
  <div class="container">
    <img class="foto" src="ceviche.webp" alt="Foto de cerca de un plato de ceviche, plato típico a base de pescado"/>
    <h2 class="titulo"><span class="negrita">Conocé</span><br>nuestros sabores</h2>
    <a class="boton" href="/Menu-Status.pdf" target="_blank">Menú completo</a>
  </div>
</section>

<style>
  section {
    background-color: var(--color-secundario);
  }

  .container {
    display: grid;
    grid-gap: 0 64px;
    grid-template-areas:
        "foto titulo"
        "foto boton";
    grid-template-columns: 55% auto;
    margin: 0 auto;
    max-width: 1280px;
    padding: 64px;
    width: 100%;
  }

  .foto {
    grid-area: foto;
    width: 100%;
  }

  .titulo {
    font-size: 3.5rem;
    font-weight: normal;
    grid-area: titulo;
    margin-top: 0;
    place-self: end;
  }

  .negrita {
    font-weight: bold;
  }

  .boton {
    background-color: var(--blanco);
    border-radius: 0 32px;
    box-shadow: rgb(0 0 0 / 10%) 5px 5px 0 0;
    color: var(--color-primario);
    display: block;
    font-weight: 600;
    grid-area: boton;
    margin: 0 auto;
    max-width: 350px;
    padding: 16px;
    place-self: start;
    text-align: center;
    text-decoration: none;
    width: 100%;
  }

  @media (max-width: 768px) {
    .container {
      grid-template-areas: "foto" "titulo" "boton";
      grid-template-columns: auto;
      padding: 32px;
    }

    .titulo {
      font-size: 2.5rem;
      margin: 24px 0 32px;
      place-self: start;
    }

    .boton {
      max-width: unset;
    }
  }

</style>
